import { Grid, Stack, Typography } from "@mui/material";
import { SocialIcon } from "react-social-icons";

const About = () => {
    return (
        <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >

            <Typography>
                This is just my playground :)
            </Typography>
            <Typography marginBottom="1em">
                And this is me:
            </Typography>
            <Stack direction="row" spacing={2} alignItems="stretch">
                <SocialIcon network="facebook" url="https://www.facebook.com/mihai.micuda/"></SocialIcon>
                <SocialIcon network="linkedin" url="https://www.linkedin.com/in/mihai-micuda-63a50038/"></SocialIcon>
                <SocialIcon network="instagram" url="https://www.instagram.com/mihai.micuda/"></SocialIcon>
            </Stack>

        </Grid>
    );
}

export default About;