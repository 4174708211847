import { Button, Card, Stack, Typography } from "@mui/material";
import { AddMoreNounsDialog } from "./AddMoreNounsDialog.js"

const ScoreCard = (params) => {


    return (
        <Card raised="true" className="flex items-center justify-center" sx={{ height: '300px', width: '500px' }}>
            <Stack direction="row" spacing={8} alignItems="center">
                <Stack direction="column" spacing={2} alignItems="center">
                    <Typography fontSize="4em">
                        {params.counter.correct}/{params.counter.all}
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={params.newSession}
                    >
                        New session
                    </Button>

                </Stack>
                <AddMoreNounsDialog></AddMoreNounsDialog>
            </Stack>


        </Card>)
}

export default ScoreCard;