import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import { useNavigate } from 'react-router-dom';

const pages = [
   {name : "Home", route : "/"},
   {name : "der-die-das Game", route : "/derDieDas"},
   {name : "About", route : "/about"},

]

const NavBar = () => {
   const navigate = useNavigate();

   return (
      <Box sx={{ flexGrow: 1 }}>
         <AppBar position="static">
            <Toolbar>
               <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
               >
                  <MenuIcon />
               </IconButton>


               {pages.map((page) => (
                  <Button
                     key={page.name}
                     onClick={() => navigate(page.route)}
                     sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                     {page.name}
                  </Button>
               ))}

            </Toolbar>
         </AppBar>
      </Box>
   );
};

export default NavBar;