import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import NavBar from './components/NavBar';
import DerDieDasGame from './pages/DerDieDasGame';


function App() {
  return (
    <>
      <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/derDieDas' element={<DerDieDasGame/>}></Route>
      </Routes>
    </>
  );
}

export default App;
