const Home = () => {
    return (
        <div className="App">
            <div className="h-screen flex items-center justify-center" data-size>
                <img
                    src="/emoticon-937602_1280.png"
                    style={{ width: "20%", height: "auto" }}
                    // width={500}
                    // height={640}
                    alt="Picture of the author"
                />
            </div>
        </div>
    );
}

export default Home;