const nounData = [{
    name: "Auto",
    article: "das"
},
{
    name: "Tisch",
    article: "der"
},
{
    name: "Baby",
    article: "das"
},
{
    name: "Frau",
    article: "die"
}
];


export function getNounData() {
    return nounData;
}

export function addNoun(noun) {
    console.log(noun)
    const nounParts = getNounParts(noun)
    if(!validateNounParts(nounParts)) {
        return;
    }

    console.log("before push", nounData)
    nounData.push({article : nounParts[1], name : nounParts[2] })
    console.log("after push", nounData)
}

function validateNounParts(nounParts) {
    console.log("noun parts", nounParts)
    if(nounParts == null || nounParts.length !== 3) {
        console.log("noun regex does nt have 3 parts")
        return false;
    }
    if(!["der", "die", "das"].includes(nounParts[1])) {
        return false;
    }
    return true;
}

function getNounParts(noun) {
    return /(der|die|das)\s*([^\s]*)/.exec(noun)

}