import { Button, Card, Stack, Typography, Box } from "@mui/material";
import { useState } from "react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { addNoun } from '../service/NounService.js';

export const AddMoreNounsDialog = () => {
    const [open, setOpen] = useState(false);
    const [nounToAdd, setNounToAdd] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAdd = () => {
        addNoun(nounToAdd)

    }

    const handleAddAndClose = () => {
        addNoun(nounToAdd)
        setOpen(false)
    }

    // const handleTextInput = (e) => {
    //     setNounToAdd(e.target.value)
    // }

    return (
        <Box>
            <Button variant="outlined" onClick={handleClickOpen}>
                Add nouns
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add more nouns to practice</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add noun together with article. Eg: das Baby
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="noun"
                        label="article + noun"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={nounToAdd}
                        onChange={e => setNounToAdd(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddAndClose}>Add</Button>
                    <Button onClick={handleAdd}>Add more</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
