import { Button } from "@mui/material";

export const DerDieDasButton = (params) => {
    
    const getBtnColor = () => {
        if(!params.selectionState[params.article].selected) {
            return params.defaultColor;
        }

        console.log(params.article, params.selectionState[params.article].correct)
        return params.selectionState[params.article].correct ? "green" : "red" 
    }

    const styles = {
        btnStyle: {
            backgroundColor: getBtnColor(),
            maxWidth: '140px', maxHeight: '50px', minWidth: '140px', minHeight: '50px'
        }
    }

    return (
        <>
            <Button
                variant="contained"
                size="large"
                style={styles.btnStyle}
                onClick={params.handleClick}
                disabled={params.disabled}
            >
                {params.text}
            </Button>
        </>
    );
};