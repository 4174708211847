import { Button, Card, Grid, Paper } from "@mui/material";
import Stack from '@mui/material/Stack';
import { useState } from "react";
import { DerDieDasButton } from '../components/DerDieDasButton';
import { getNounData } from "../service/NounService.js";
import ScoreCard from "../components/ScoreCard.js";

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const initialSelectionState = {
    der: {
        selected: false,
        correct: false,
        defaultColor: "lightBlue"
    },
    die: {
        selected: false,
        correct: false,
        defaultColor: "pink"
    },
    das: {
        selected: false,
        correct: false,
        defaultColor: "lightGray"
    }
}

const articles = ["der", "die", "das"];

const DerDieDasGame = () => {

    const nounData = getNounData();

    const [articlePlaceholder, setArticlePlaceholder] = useState("...");
    const [currentNoun, setCurrentNoun] = useState(nounData[getRandomInt(nounData.length)]);
    const [articleSelection, setArticleSelection] = useState(JSON.parse(JSON.stringify(initialSelectionState)));
    const [allCounter, setAllCounter] = useState(0);
    const [correctCounter, setCorrectCounter] = useState(0);

    const handleClick = (article, id) => {
        setArticleSelection(previous => {
            const newSelection = { ...previous };
            newSelection[article].selected = true;
            newSelection[article].correct = currentNoun.article === article;
            return newSelection;
        });

        setArticlePlaceholder(currentNoun.article)
        setAllCounter(previous => { return previous + 1 })
        if (currentNoun.article === article) {
            setCorrectCounter(previous => previous + 1)
        }
    }

    const nextNoun = () => {
        setCurrentNoun(nounData[getRandomInt(nounData.length)]);
        setArticlePlaceholder("...");
        setArticleSelection(JSON.parse(JSON.stringify(initialSelectionState)));
    }

    const nextSession = () => {
        setAllCounter(0);
        setCorrectCounter(0);
        nextNoun();
    }

    const isSelectionDone = () => {
        return articleSelection["der"].selected || articleSelection["die"].selected || articleSelection["das"].selected
    }

    return (
        <div>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '100vh' }}
                >
                    <Grid item>
                        <ScoreCard newSession={nextSession} counter={{ all: allCounter, correct: correctCounter }}></ScoreCard>
                    </Grid>
                    <Grid item>
                        <Card raised="true" className="flex items-center justify-center" sx={{ height: '300px', width: '500px' }}>
                            <Stack direction="column" spacing={2} alignItems="stretch">
                                <div style={{ fontSize: "3em" }}>
                                    {articlePlaceholder} {currentNoun.name}
                                </div>
                                <Button variant="contained" onClick={nextNoun}>next</Button>
                            </Stack>
                        </Card>

                    </Grid>

                    <Grid item>
                        <Stack direction="row" spacing={2} alignItems="stretch" sx={{ width: '500px', paddingBlockStart: "1.5em", paddingLeft: "1.5em" }}>
                            {articles.map(article =>

                                <DerDieDasButton
                                    id={article}
                                    key={article}
                                    article={article}
                                    defaultColor={articleSelection[article].defaultColor}
                                    disabled={isSelectionDone()}
                                    selectionState={articleSelection}
                                    handleClick={() => handleClick(article, article)}
                                    text={article} />
                            )}
                        </Stack>
                    </Grid>
                </Grid>

        </div>


    );
}

export default DerDieDasGame;